
<template>
    <div>
		<card v-for="(item, i) in row.message">
			<Row class="expand-row" style="text-align: center;">
				<Col span="4">
				<Avatar :src="item.userImage" size="32"/>
					 <!--<img :src="item.userImage" style="display: block; margin: 0 auto; margin-top: 0.5rem;"/>-->
					<!--<img src="../assets/user.png" style="display: block; margin: 0 auto; margin-top: 0.5rem;"/>-->
					<span>{{item.userName}}</span>
				</Col>
				<Col span="20" style="text-align: left;">
				    <div class="content">{{item.comment}}</div>
					<p style="color: darkgray; text-align: right;">{{item.time}}</p>
				</Col>
			</Row>
		</card>
		<card>
			<Input v-model="commentInput" type="textarea" :rows="3" placeholder="Leave a comment" style="padding: 0 2rem;"/>
			<div style="width: 100%; text-align: right;">
				<Button v-if="row.opened=='Open'" type="primary" @click="close" style="margin-top: 0.5rem;margin-bottom: 0.5rem; margin-right: 2rem;">Close Issue</Button>
				<Button v-if="row.opened!='Open'" type="primary" @click="open" style="margin-top: 0.5rem;margin-bottom: 0.5rem; margin-right: 2rem;">Reopen Issue</Button>
				<Button v-if="row.opened=='Open'" type="primary" @click="comment" style="margin-top: 0.5rem;margin-bottom: 0.5rem; margin-right: 2rem;">Comment</Button>

			</div>
		</card>
    </div>
</template>

<script>
    export default {
        props: {
            row: Object
        },
		data(){
			return{
				commentInput: '',
			}
		},
		methods: {
			comment(){
				var Tself = this;
                var userId=this.$cookies.get("user_id")
                var username=this.$cookies.get("user_name")
                var userPic = this.$cookies.get('user_picture')
				this.$axios({
					headers: {
						'Content-Type': 'application/json'
					},
					method: 'post',
					url: '/issue/reply',
					data: {
						content: Tself.commentInput,
						id: 0,
						issueId: Tself.row.issueId,
						parentId: 0,
						time: '',
						userId: userId,
						username: username+"---"+userPic
					}
				})
				.then(function (response) {
					var message = response.data.message;
					if(message == "ok"){
						Tself.$router.go(0)
					}
					else{
						Tself.$Message.warning('Faliure');
						console.log(response)
					}
				})
				.catch(function (error) {
					Tself.$Message.warning('Faliure');
					console.log(error);
				});
			},
            close(){
                var Tself = this;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/issue/close',
                    data: parseInt(Tself.row.issueId)

                })
                    .then(function (response) {
                        var message = response.data.message;
                        if(message == "ok"){
                            Tself.$router.go(0)
                        }
                        else{
                            Tself.$Message.warning('Faliure');
                            console.log(response)
                        }
                    })
                    .catch(function (error) {
                        Tself.$Message.warning('Faliure');
                        console.log(error);
                    });
            },
			open(){
                var Tself = this;
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/issue/open',
                    data: parseInt(Tself.row.issueId)

                })
                    .then(function (response) {
                        var message = response.data.message;
                        if(message == "ok"){
                            Tself.$router.go(0)
                        }
                        else{
                            Tself.$Message.warning('Faliure');
                            console.log(response)
                        }
                    })
                    .catch(function (error) {
                        Tself.$Message.warning('Faliure');
                        console.log(error);
                    });
			}
		}
    };
</script>

<style scoped>
    .expand-row{
        margin-bottom: 4px;
    }

	.content {
		width: 100%;
		height: auto;
		word-wrap:break-word;
		word-break:break-all;
		overflow: hidden;
	}
</style>
