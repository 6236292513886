<template>
  <div class="contentCard">
    <div class="issueHeader">
      <Layout style="background-color: #ffffff; margin-bottom: 1rem">
        <Content>
          <Input
            search
            v-model="searchInput"
            @on-search="search(status)"
          ></Input>
        </Content>
        <Sider width="150" style="background: #ffffff; padding-left: 1.25rem">
          <Button
            type="success"
            style="float: left; margin-left: 1rem"
            @click="modal1 = true"
            >New issue</Button
          >
          <Modal
            v-model="modal1"
            title="Create new issue"
            @on-ok="ok"
            @on-cancel="cancel"
          >
            <div
              style="
                display: flex;
                justify-content: stretch;
                align-items: center;
                margin-bottom: 1rem;
              "
            >
              <span style="flex: none; width: 5rem">Title: </span>
              <Input v-model="newIssue.title" placeholder="Title"></Input>
            </div>
            <div
              style="
                display: flex;
                justify-content: stretch;
                align-items: flex-start;
              "
            >
              <span style="flex: none; width: 5rem">Comment: </span>
              <Input
                v-model="newIssue.comment"
                type="textarea"
                :rows="4"
                placeholder="Leave a comment"
              />
            </div>
          </Modal>
        </Sider>
      </Layout>
    </div>
    <Row
      style="
        padding: 0.5rem;
        border-radius: 5px 5px 0 0;
        background-color: #f6f8fa;
        border: #e1e4e8 solid 0.0625rem;
      "
    >
      <Col span="8" style="text-align: left">
        <Button
          v-if="status == 'Open'"
          type="text"
          style="background-color: white"
          icon="ios-alert-outline"
          @click="search('Open')"
          >Open</Button
        >
        <Button
          v-else
          type="text"
          style="background-color: transparent"
          icon="ios-alert-outline"
          @click="search('Open')"
          >Open</Button
        >
        <Button
          v-if="status == 'Open'"
          type="text"
          style="background-color: transparent"
          icon="md-checkmark"
          @click="search('Closed')"
          >Closed</Button
        >
        <Button
          v-else
          type="text"
          style="background-color: white"
          icon="md-checkmark"
          @click="search('Closed')"
          >Closed</Button
        >
      </Col>
      <Col span="16" style="text-align: right; line-height: 2rem">
        <Dropdown
          trigger="click"
          style="margin: 0 1rem; text-align: left"
          placement="bottom-end"
          @on-click="selectSort"
        >
          <a href="javascript:void(0)" style="color: #586069">
            Sort
            <Icon type="ios-arrow-down"></Icon>
          </a>
          <DropdownMenu slot="list" style="width: 15rem">
            <DropdownItem>
              Newest
              <Icon
                type="md-checkmark"
                v-if="sort == 'Newest'"
                size="18"
                style="float: right"
              />
            </DropdownItem>
            <DropdownItem>
              Oldest
              <Icon
                type="md-checkmark"
                v-if="sort == 'Oldest'"
                size="18"
                style="float: right"
              />
            </DropdownItem>
            <!--<DropdownItem>-->
            <!--Most commented-->
            <!--<Icon type="md-checkmark" v-if="sortMethod==3" size="18" style="float: right;"/>-->
            <!--</DropdownItem>-->
            <!--<DropdownItem>-->
            <!--Least commented-->
            <!--<Icon type="md-checkmark" v-if="sortMethod==4" size="18" style="float: right;"/>-->
            <!--</DropdownItem>-->
            <!--<DropdownItem>-->
            <!--Recently updated-->
            <!--<Icon type="md-checkmark" v-if="sortMethod==5" size="18" style="float: right;"/>-->
            <!--</DropdownItem>-->
          </DropdownMenu>
        </Dropdown>
      </Col>
    </Row>
    <div class="issueTable">
      <Table :columns="columns1" :data="tableData" :show-header="false">
        <template slot-scope="{ row }" slot="issueName">
          <div style="display: flex">
            <!-- <img v-if="row.opened=='Open'" src="../assets/process.png" style="width: 1rem;height: 1rem;margin-top: 1.6rem;">
					<img v-else src="../assets/closed.png" style="width: 1rem;height: 1rem;margin-top: 1.6rem;"> -->
            <div class="IssueDiv">
              <span style="color: #000000; display: block; font-weight: 600">{{
                row.issueName
              }}</span>
              <span style="font-size: 0.75rem">
                {{ row.issueTime }} {{ row.opened }} by
                {{ row.authorName }}</span
              >
            </div>
          </div>
        </template>
        <template slot-scope="{ row }" slot="commentsNum">
          <div v-if="row.commentsNum != 0" style="text-align: right">
            <Icon type="ios-chatboxes-outline" size="20" />
            {{ row.commentsNum }}
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import expandRow from "./expandRow.vue";
export default {
  components: { expandRow },
  data() {
    return {
      select: "1",
      status: "Open",
      openNum: 10,
      closeNum: 9,
      searchInput: "",
      sortMethod: 1,
      modal1: false,
      sort: "Newest",
      newIssue: {
        title: "",
        comment: "",
      },
      columns1: [
        {
          type: "expand",
          width: 50,
          render: (h, params) => {
            return h(expandRow, {
              props: {
                row: params.row,
              },
            });
          },
        },
        {
          title: "Name",
          slot: "issueName",
        },
        {
          title: "commentsNum",
          slot: "commentsNum",
          width: 150,
        },
      ],
      tableData: [],
    };
  },
  methods: {
    selectSort(name) {},
    ok() {
      var Tself = this;
      var userId = this.$cookies.get("user_id");
      var username = this.$cookies.get("user_name");
      var userPic = this.$cookies.get("user_picture");
      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/issue/add",
        data: {
          content: Tself.newIssue.title,
          id: 0,
          projectId: Tself.projectId,
          status: false,
          time: "",
          userId: userId, //todo 改用户
          username: username,
        },
      })
        .then(function (response) {
          var items = response.data.data.items;
          console.log(items);
          Tself.$axios({
            headers: {
              "Content-Type": "application/json",
            },
            method: "post",
            url: "/issue/reply",
            data: {
              content: Tself.newIssue.comment,
              id: 0,
              issueId: items.id,
              parentId: 0,
              time: "",
              userId: userId, //todo 改用户
              username: username + "---" + userPic,
            },
          })
            .then(function (response) {
              Tself.$router.go(0);
            })
            .catch(function (error) {
              Tself.$Message.warning("Faliure");
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cancel() {},
    init(open) {
      this.tableData = [];
      var Tself = this;
      var val = this.$route.query.id;
      this.projectId = val;

      var isOpen = true;
      if (open != "Open") {
        isOpen = false;
      }

      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/issue/find",
        data: {
          projectId: val,
          query: "",
          status: !isOpen, //传false代表查询open的issue，传true代表查closed的issue
          sort: this.sort,
        },
      })
        .then(function (response) {
          var data = response.data.data.items;
          for (var i = 0; i < data.length; i++) {
            Tself.tableData.push({
              issueName: data[i].content,
              issueId: data[i].id,
              issueTime: data[i].time,
              authorName: data[i].username,
              opened: open,
              commentsNum: 0,
              message: [],
            });
          }
          for (var i = 0; i < Tself.tableData.length; i++) {
            Tself.$axios({
              headers: {
                "Content-Type": "application/json",
              },
              method: "post",
              url: "/issue/findReplyByIssueId",
              data: Tself.tableData[i].issueId,
            })
              .then(function (response) {
                var data2 = response.data.data.items;
                if (data2.length == 0) {
                  return;
                }
                var idNum = 0;
                for (var j = 0; j < Tself.tableData.length; j++) {
                  if (Tself.tableData[j].issueId == data2[0].issueId) {
                    idNum = j;
                    break;
                  }
                }
                Tself.tableData[idNum].commentsNum = data2.length;
                for (let j = 0; j < data2.length; j++) {
                  let temp = data2[j].username.split("---");
                  console.log(temp[1]);
                  let userImage = temp[1];
                  if (userImage === "null" || userImage === "") {
                    userImage =
                      "https://blocksop.com/ownerbased/record/3d2aef28cf284456ba9a7f52fff6f91a.png";
                  }
                  console.log(userImage);
                  Tself.tableData[idNum].message.push({
                    userImage: userImage,
                    userName: temp[0],
                    comment: data2[j].content,
                    time: data2[j].time,
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    search(open) {
      this.status = open;
      this.tableData = [];
      var Tself = this;
      var val = this.$route.query.id;
      this.projectId = val;

      var isOpen = true;
      if (open != "Open") {
        isOpen = false;
      }

      this.$axios({
        headers: {
          "Content-Type": "application/json",
        },
        method: "post",
        url: "/issue/find",
        data: {
          projectId: val,
          query: this.searchInput,
          status: !isOpen, //传false代表查询open的issue，传true代表查closed的issue
          sort: this.sort,
        },
      })
        .then(function (response) {
          var data = response.data.data.items;
          Tself.tableData.length = 0;
          for (var i = 0; i < data.length; i++) {
            Tself.tableData.push({
              issueName: data[i].content,
              issueId: data[i].id,
              issueTime: data[i].time,
              authorName: data[i].username,
              opened: open,
              commentsNum: 0,
              message: [],
            });
          }
          for (var i = 0; i < Tself.tableData.length; i++) {
            Tself.$axios({
              headers: {
                "Content-Type": "application/json",
              },
              method: "post",
              url: "/issue/findReplyByIssueId",
              data: Tself.tableData[i].issueId,
            })
              .then(function (response) {
                var data2 = response.data.data.items;
                if (data2.length == 0) {
                  return;
                }
                var idNum = 0;
                for (var j = 0; j < Tself.tableData.length; j++) {
                  if (Tself.tableData[j].issueId == data2[0].issueId) {
                    idNum = j;
                    break;
                  }
                }
                Tself.tableData[idNum].commentsNum = data2.length;
                for (var j = 0; j < data2.length; j++) {
                  var temp = data2[j].username.split("---");
                  console.log(temp[1]);
                  Tself.tableData[idNum].message.push({
                    userImage: temp[1],
                    userName: temp[0],
                    comment: data2[j].content,
                    time: data2[j].time,
                  });
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    this.init("Open");
  },
};
</script>

<style>
.option {
  text-align: left;
  width: 15.625rem;
}
a:hover {
  color: #0366d6 !important;
}
.ivu-layout-content {
  overflow-x: inherit !important;
}
.issueTable {
  border-left: #e1e4e8 solid 0.0625rem;
  border-right: #e1e4e8 solid 0.0625rem;
  border-bottom: #e1e4e8 solid 0.0625rem;
  border-radius: 0 0 4px 4px;
}
.IssueDiv {
  float: left;
  margin: 0.8rem 0;
  margin-left: 0.6rem;
}
</style>
